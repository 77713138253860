import React from 'react'
import Layout from '../components/layout'
import bgImage from '../images/bg-image.jpg'

const IndexPage = () => (
  <Layout>
    <div className='content-container'
          style={{
            position: 'absolute',
            bottom: '0',
            right: '0',
            left: '0',
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            maxWidth: '84vw',
            color: '#fff',
            backgroundColor: '#5632c9',
            backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
          }}
        >
    <div style={{fontFamily: 'greycliff', textAlign: 'center', margin: 'auto', padding: '1rem 3vw'}}>
    <h1 style={{fontFamily: 'greycliff', fontWeight: 'bold', marginBottom: '.45rem'}}>Nous sommes Scrine</h1>
    <h2 style={{fontFamily: 'greycliff', fontStyle: 'italic', fontWeight: 'normal', fontSize: '1.55rem', marginBottom: '2rem'}}>société de production audiovisuelle.</h2>
    <p style={{fontFamily: 'greycliff', maxWidth: 690, margin: 'auto', textAlign: 'center'}}>Nous créons, réalisons et déployons des contenus éditoriaux en vidéos. Séries documentaires, films documentaires, vidéos explicatives, motion design, nous exploitons l’ensemble des formats imaginables au service de vos ambitions éditoriales. Notre spécialité ? Penser et produire des contenus sur-mesure à destination des millenials et des plateformes.</p>
    <a
    href="mailto:hello@scrineprod.fr"
    style={{
      backgroundColor: '#8eeffd',
      fontFamily: 'greycliff',
      fontStyle: 'italic',
      fontWeight: 'bold',
      color: '#333c56',
      display: 'inline-block',
      marginTop: '2rem',
      padding: '.5rem 2rem',
      textDecoration: 'none',
      boxShadow: '6px 6px 0px -1px rgba(247,64,198,1)',
    }}
    className= 'animated'
    >
    On en parle ?
    </a>
    </div>
    </div>
  </Layout>
)

export default IndexPage
